import React, {useMemo, useState} from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {Box, CardActionArea} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faEllipsisH, faFileArrowUp, faNoteSticky, faTrash,} from "@fortawesome/free-solid-svg-icons";
import {makeStyles} from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {
    selectImageAnnotationAction
} from '../../../../../../app/actions/v2/dentist/clinicalExam/clinicalExamFilterAction';
import theme from '../../../../../../theme';
import DeleteConfirmationPopUp from '../../../../common/DeleteConfirmationPopUp';
import {
    deletePhotosRequestAction,
    selectPhotosAction,
    uploadPhotosModelAction
} from '../../../../../../app/actions/v2/dentist/clinicalExam/exam/uploadPhotosXrayAction';
import {RootState} from '../../../../../../app/reducers/v2/rootReducer';
import {referencePhotos, referenceXray} from '../../../../../../utils/v2/photoXrayConstant';
import {resourceDateFormate} from "../../../../../../utils/helper";
import {
    completeCheckout,
    photo,
    viewExam
} from '../../../../../../utils/v2/clinicalExamConstant';
import {NPE} from '../../../../../../utils/v2/examConstant';
import AddCommentsPopup from "./AddCommentsPopup";

const useStyles = makeStyles((theme: any) => ({
    noHover: (isWellnessProfile: any) => ({
        background: isWellnessProfile ? theme.palette.background.secondaryPaper : theme.palette.common.white,
        padding: "16px 0 !important",
        "&:hover": {
            backgroundColor: isWellnessProfile ? theme.palette.background.secondaryPaper : theme.palette.common.white,
        },
    }),
    actionArea: {
        "&:hover .MuiCardActionArea-focusHighlight": {
            opacity: 0, // remove the opacity on hover
        },
    },
    dropDownBox: {
        border: `1px solid ${theme.palette.grey["Gray80"]}`,
        borderRadius: "50% !important",
        width: "32px !important",
        height: "32px !important",
        minWidth: "32px !important",
    },
    menuItem: {
        marginBottom: "10px",
        alignItems: "center !important",
    },
    imageBox: {
        [theme.breakpoints.between(1760, 1920)]: {
            height: '205px',
        },
        [theme.breakpoints.between(1640, 1760)]: {
            height: '190px',
        },
        [theme.breakpoints.down(1415)]: {
            height: '190px',
        },
        [theme.breakpoints.between(1000, 1235)]: {
            height: '220px',
        },
        [theme.breakpoints.between(750, 835)]: {
            height: '170px',
        },
        [theme.breakpoints.down(750)]: {
            height: '220px',
        },
    }
}));

type Props = {
    data: any;
};
const PhotoXrayCard = (props: Props) => {
    const {data} = props;
    const dispatch = useDispatch();
    const location = useLocation()
    const isWellnessProfile = location?.pathname?.includes('/v2/dentist/wellness-profile/')
    const classes = useStyles(isWellnessProfile);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [id, setId] = useState("") as any;
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [openCommentsPopup, setOpenCommentsPopup] = useState(false);
    const [selectImageXray, setSelectImageXray] = useState(null)
    const open = Boolean(anchorEl);
    const {
        uploadPhotoXray: {deletePhotoXrayLoader, photosXrayData},
        clinicalExamFilters: {findingType},
        appointmentsReducer: {appointmentDetails},
        wellnessProfileFilters: {wellnessProfileStep, wellnessProfileData}
    } = useSelector((state: RootState) => state);
    const tabData = isWellnessProfile ? wellnessProfileStep : findingType
    const isPhoto = tabData === photo

    const disabledField = (name: string) => {
        if (name === 'Annotation' || name === 'Comments') {
            return false
        }
        return [completeCheckout, viewExam]?.includes(appointmentDetails?.sub_status) || wellnessProfileData?.is_wellness_locked
    }
    const handleClick = (event: any) => {
        setAnchorEl(event?.currentTarget);
    };
    const handleClose = (option?: string, data?: any) => {
        if (option === 'Upload new image') {
            uploadNewImage(data)
        }
        if (option === 'Annotation') {
            gotoAnnotation(data?.ID)
        }
        if (option === 'Remove') {
            setId(data?.ID)
            setOpenDeletePopup(true)
        }
        if (option === 'Comments') {
            setId(data?.ID)
            setSelectImageXray(data)
            setOpenCommentsPopup(true)
        }
        setAnchorEl(null);
    };
    const gotoAnnotation = (image_id?: string) => {
        dispatch(selectImageAnnotationAction(image_id));
        setAnchorEl(null);
    }
    const getImagesName = () => {
        let uploaded = [] as any
        const imageXrayData = JSON.parse(JSON.stringify(isPhoto ? referencePhotos : referenceXray))
        imageXrayData?.map((res: any) => {
            if (res?.title) {
                uploaded.push(res?.title)
            } else {
                uploaded = []
            }
            return true
        })
        return uploaded
    }
    const filterImageData = () => {
        const filteredPhotoData = referencePhotos as any
        const examType = appointmentDetails?.appointmentType || NPE;
        const examTypeWiseFilteredData = filteredPhotoData?.filter((f: any) => f?.examType?.includes(examType));
        let imageXrayData = JSON.parse(JSON.stringify(isPhoto ? examTypeWiseFilteredData : referenceXray))
        const newTOAdd = photosXrayData?.filter((f: any) => !getImagesName()?.includes(f?.image_name)) || [] as any
        return [...imageXrayData, ...newTOAdd]
    }
    const selectDataName = (res: any) => {
        if (res?.ID) {
            return res?.image_name
        } else {
            return res?.title
        }
    }
    const uploadNewImage = (data: any) => {
        let selectedData = null as any
        filterImageData()?.filter((r: any) => {
            if (selectDataName(r) === data?.image_name) {
                return selectedData = {
                    ...r,
                    image_id: data?.ID
                }
            }
            return true
        })
        dispatch(selectPhotosAction(selectedData))
        dispatch(uploadPhotosModelAction(true));
    }
    const dropDownOption = [
        {
            name: "Upload new image",
            icon: faFileArrowUp,
        },
        {
            name: "Annotation",
            icon: faNoteSticky,
        },
        {
            name: "Remove",
            icon: faTrash,
        },
        {
            name: "Comments",
            icon: faEdit,
        },
    ];
    const handleDelete = () => {
        dispatch(
            deletePhotosRequestAction({
                image_id: id,
                callback: () => {
                    setOpenDeletePopup(false);
                },
            })
        );
    };
    const disabledComment = useMemo(() => {
        return [completeCheckout, viewExam].includes(appointmentDetails?.sub_status) || wellnessProfileData?.is_wellness_locked
    }, [appointmentDetails, wellnessProfileData?.is_wellness_locked]);
    return (
        <React.Fragment>
            <Card>
                <CardActionArea disableRipple className={classes.actionArea}>
                    <CardMedia
                        className={classes.imageBox}
                        onClick={() => gotoAnnotation(data?.ID)}
                        component="img"
                        // height="226"
                        image={data?.image_path}
                        alt="tooth"
                        sx={{
                            borderRadius: "8px",
                            maxWidth: "440px !important",
                            objectFit: "cover !important ",
                            width: "100% !important",
                            height: {
                                xl: '220px',
                                lg: '200px',
                                md: '190px',
                                sm: '210px',
                                xs: '220px',
                            },
                        }}
                    />
                    <CardContent
                        className="d-flex justify-content-between"
                        classes={{root: classes.noHover}}
                    >
                        <Box
                            className="d-flex flex-col align-start"
                        >
                            <Typography className="fw-medium f-16 f-w-500 lh-16 labelColor">
                                {data?.image_name}
                            </Typography>
                            {data?.created_at &&
                                <Typography className="fw-regular f-12 lh-16" color={theme.palette.text.secondary}
                                            mt={'5px'}>
                                    Created: {resourceDateFormate(data?.created_at)}
                                </Typography>}
                            {data?.updated_at &&
                                <Typography className="fw-regular f-12 lh-16" color={theme.palette.text.secondary}>
                                    Last updated: {resourceDateFormate(data?.updated_at)}
                                </Typography>}
                        </Box>
                        <Box className={`d-flex justify-center align-center ${classes.dropDownBox}`}>
                            <FontAwesomeIcon
                                onClick={handleClick}
                                icon={faEllipsisH}
                                className="f-w-900 f-16 lh-16 labelColor"
                            />
                            <Menu
                                sx={{
                                    height: "200px !important",
                                    '& .MuiPaper-root': {
                                        maxHeight: `calc(100% - 8px)`
                                    }
                                }}
                                id="demo-customized-menu"
                                MenuListProps={{
                                    "aria-labelledby": "demo-customized-button",
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={() => handleClose()}
                            >
                                {
                                    dropDownOption?.map((r: any, i: number) =>
                                        <MenuItem
                                            key={i} className={`d-flex ${classes.menuItem}`}
                                            onClick={() => handleClose(r?.name, data)}
                                            disableRipple
                                            sx={{opacity: '1 !important'}}
                                            disabled={(r?.name !== 'Annotation') && disabledField(r?.name)}
                                        >
                                            <FontAwesomeIcon
                                                icon={r?.icon}
                                                width={16}
                                                height={14}
                                                color={(disabledField(r?.name) && r?.name !== 'Annotation') ? theme.palette.text.secondary : theme.palette.text.darkGrey}
                                                className="f-w-900 fw-medium f-14 lh-16"
                                            />
                                            <Typography
                                                ml={'12px'}
                                                className="f-w-500 fw-regular f-16 lh-21"
                                                color={(disabledField(r?.name) && r?.name !== 'Annotation') ? theme.palette.text.secondary : theme.palette.text.darkGrey}
                                            >
                                                {r?.name}
                                            </Typography>
                                        </MenuItem>
                                    )
                                }
                            </Menu>
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
            <DeleteConfirmationPopUp
                open={openDeletePopup}
                setOpen={setOpenDeletePopup}
                deleteHandler={handleDelete}
                deleteText={isPhoto ? "photo" : "xray"}
                loader={deletePhotoXrayLoader}
            />
            {openCommentsPopup && <AddCommentsPopup disabledComment={disabledComment} open={openCommentsPopup}
                                                    setOpen={setOpenCommentsPopup}
                                                    isPhoto={isPhoto}
                                                    selectImageXray={selectImageXray}
            />}
        </React.Fragment>
    );
};

export default PhotoXrayCard;
