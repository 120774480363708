import {all, call, put, takeEvery} from 'redux-saga/effects';
import API from '../../../../../utils/api';
import {errorToaster, successToaster} from '../../../../../utils/helper';
import {updateAppointmentDetailsRequestAction} from "../../../../actions/v2/dentist/appointmentsAction";
import {
    getRiskFilterScoreRequestFailureAction,
    getRiskFilterScoreRequestSuccessAction,
    openAdditionalNotesAction,
    putAdditionalNotesRequestFailureAction,
    putAdditionalNotesRequestSuccessAction,
    sendWellnessEmailLinkRequestSuccessAction,
    sendWellnessEmailLinkRequestFailureAction
} from '../../../../actions/v2/dentist/clinicalExam/clinicalExamFilterAction';
import {
    GET_RISK_FILTER_SCORE_REQUEST,
    PUT_ADDITIONAL_NOTES_REQUEST,
    SEND_WELLNESS_EMAIL_LINK_REQUEST
} from '../../../../ActionTypes/v2/dentist/clinicalExam/clinicalExamFilters';


function* riskFilterScoreRequestSaga(payload: any): any {
    try {
        const response = yield API.get(
            payload.payload?.isPusher ?
                `/v2/risk/profile/filter/${payload.payload?.appointment_id}?is_pusher=${payload?.payload?.isPusher}&core_apis=true`
                :
                `/v2/risk/profile/filter/${payload.payload?.appointment_id}`
        )
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data?.detail)
        }
        yield put(
            getRiskFilterScoreRequestSuccessAction(response?.data?.detail)
        )

    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            getRiskFilterScoreRequestFailureAction({
                riskFilterScoreError: e?.message,
            })
        )
    }
}

function* putAdditionalNotesRequestSaga(payload: any): any {
    try {
        const response = yield API.put(`/v2/appointments/additional/notes`, payload.payload)
        yield put(putAdditionalNotesRequestSuccessAction(response?.data?.detail))
        yield put(
            updateAppointmentDetailsRequestAction({
                key: 'additional_notes',
                value: payload.payload?.additional_notes
            })
        )
        yield put(openAdditionalNotesAction(false))
        successToaster(response?.data?.meta?.message)
    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            putAdditionalNotesRequestFailureAction(e?.message)
        )
    }
}


function* sendWellnessEmailLinkRequestSaga(payload: any): any {
    try {
        const response = yield API.post(`/v2/wellness-profile/send-wellness-profile`, payload.payload)
        yield put(sendWellnessEmailLinkRequestSuccessAction(response?.data?.detail))
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data?.detail)
        }
        successToaster("Invitation sent successfully")
    } catch (e: any) {
        errorToaster('Invitation failed to send')
        yield put(
            sendWellnessEmailLinkRequestFailureAction(e?.message)
        )
    }
}

function* riskFilterScoreWatch() {
    yield takeEvery(GET_RISK_FILTER_SCORE_REQUEST, riskFilterScoreRequestSaga)
}

function* putAdditionalNotesWatch() {
    yield takeEvery(PUT_ADDITIONAL_NOTES_REQUEST, putAdditionalNotesRequestSaga)
}

function* sendWellnessEmailLinkWatch() {
    yield takeEvery(SEND_WELLNESS_EMAIL_LINK_REQUEST, sendWellnessEmailLinkRequestSaga)
}

export default function* clinicalExamFiltersSaga() {
    yield all([
        riskFilterScoreWatch(),
        putAdditionalNotesWatch(),
        sendWellnessEmailLinkWatch()
    ])
}
