import RootCanal11 from './rootCanal11.svg';
import RootCanal12 from './rootCanal12.svg';
import RootCanal13 from './rootCanal13.svg';
import RootCanal14 from './rootCanal14.svg';
import RootCanal15 from './rootCanal15.svg';
import RootCanal16 from './rootCanal16.svg';
import RootCanal17 from './rootCanal17.svg';
import RootCanal18 from './rootCanal18.svg';
import RootCanal21 from './rootCanal21.svg';
import RootCanal22 from './rootCanal22.svg';
import RootCanal23 from './rootCanal23.svg';
import RootCanal24 from './rootCanal24.svg';
import RootCanal25 from './rootCanal25.svg';
import RootCanal26 from './rootCanal26.svg';
import RootCanal27 from './rootCanal27.svg';
import RootCanal28 from './rootCanal28.svg';
import RootCanal31 from './rootCanal31.svg';
import RootCanal32 from './rootCanal32.svg';
import RootCanal33 from './rootCanal33.svg';
import RootCanal34 from './rootCanal34.svg';
import RootCanal35 from './rootCanal35.svg';
import RootCanal36 from './rootCanal36.svg';
import RootCanal37 from './rootCanal37.svg';
import RootCanal38 from './rootCanal38.svg';
import RootCanal41 from './rootCanal41.svg';
import RootCanal42 from './rootCanal42.svg';
import RootCanal43 from './rootCanal43.svg';
import RootCanal44 from './rootCanal44.svg';
import RootCanal45 from './rootCanal45.svg';
import RootCanal46 from './rootCanal46.svg';
import RootCanal47 from './rootCanal47.svg';
import RootCanal48 from './rootCanal48.svg';

export const rootCanalIcon = {
  RootCanal11,
  RootCanal12,
  RootCanal13,
  RootCanal14,
  RootCanal15,
  RootCanal16,
  RootCanal17,
  RootCanal18,
  RootCanal21,
  RootCanal22,
  RootCanal23,
  RootCanal24,
  RootCanal25,
  RootCanal26,
  RootCanal27,
  RootCanal28,
  RootCanal31,
  RootCanal32,
  RootCanal33,
  RootCanal34,
  RootCanal35,
  RootCanal36,
  RootCanal37,
  RootCanal38,
  RootCanal41,
  RootCanal42,
  RootCanal43,
  RootCanal44,
  RootCanal45,
  RootCanal46,
  RootCanal47,
  RootCanal48
};
