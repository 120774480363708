import {all, call, put, takeEvery} from 'redux-saga/effects';
import {
    changeWellnessComplaintsLocalRequestAction,
    changeWellnessComplaintsRequestAction, changeWellnessHygieneRegimeRequestAction,
    changeWellnessPatientPreferencesRequestAction,
    changeWellnessSocialNotesRequestAction,
    createAppointmentFromWellnessRequestFailure,
    createAppointmentFromWellnessRequestSuccess,
    getWellnessProfileFailureAction,
    getWellnessProfileSuccessAction,
    updateDentistWellnessComplaintsRequestFailure,
    updateDentistWellnessComplaintsRequestSuccess,
    updateDentistWellnessProfileRequestFailure,
    updateDentistWellnessProfileRequestSuccess,
    updateWellnessHygieneRegimeRequestFailureAction,
    updateWellnessHygieneRegimeRequestSuccessAction
} from '../../../../actions/v2/dentist/wellnessProfile/wellnessProfileFiltersAction';
import {
    CREATE_APPOINTMENT_FROM_WELLNESS_REQUEST,
    GET_WELLNESS_REQUEST,
    UPDATE_DENTIST_WELLNESS_COMPLAINTS_REQUEST,
    UPDATE_DENTIST_WELLNESS_PROFILE_REQUEST, UPDATE_WELLNESS_HYGIENE_REGIME_REQUEST
} from '../../../../ActionTypes/v2/dentist/wellnessProfile/wellnessProfileFilters';
import API from '../../../../../utils/api';
import {errorToaster, successToaster} from '../../../../../utils/helper';

// get Wellness profile data
function* getWellnessProfileRequest(payload: any): any {
    try {
        const isPatient = payload?.payload?.isPatient
        const response = yield API.get(`/v2/wellness-profile/${payload?.payload?.patient_id}?clinic_id=${payload?.payload?.clinic_id}`)
        yield put(getWellnessProfileSuccessAction(response?.data?.detail))
        if (!isPatient) {
            const wellnessData = response?.data?.detail
            yield put(changeWellnessComplaintsLocalRequestAction(wellnessData?.chief_complaint))
            yield put(changeWellnessComplaintsRequestAction({chief_complaint: wellnessData?.chief_complaint?.present?.chief_complaint}))
            yield put(changeWellnessPatientPreferencesRequestAction({patient_preferences: wellnessData?.patient_preferences}))
            yield put(changeWellnessSocialNotesRequestAction({social_note: wellnessData?.social_note}))
            yield put(changeWellnessHygieneRegimeRequestAction(wellnessData?.hygiene_regimen))
        }
    } catch (e: any) {
        errorToaster(e?.message)
        yield put(getWellnessProfileFailureAction(e?.message))
    }
}

//Edit dentist wellness Profile
function* updateDentistWellnessProfileRequestSaga(payload: any): any {
    try {
        const response = yield API.put(`/v2/wellness-profile/dentist`, payload.payload)
        yield put(
            updateDentistWellnessProfileRequestSuccess(response?.data?.detail)
        );
        if (payload.payload.callback) {
            yield call(payload.payload.callback, response.data?.detail);
        }
        successToaster(response?.data?.meta?.message);
    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            updateDentistWellnessProfileRequestFailure(e?.message)
        );
    }
}

// check un-check Complaints
function* updateDentistWellnessComplaintsRequestSaga(payload: any): any {
    try {
        const response = yield API.put(`/v2/wellness-profile/complaint`, payload.payload)
        yield put(updateDentistWellnessComplaintsRequestSuccess(response?.data?.detail));
        if (payload.payload.callback) {
            yield call(payload.payload.callback, response.data?.detail);
        }
        // successToaster(response?.data?.meta?.message);
    } catch (e: any) {
        errorToaster(e?.message)
        yield put(updateDentistWellnessComplaintsRequestFailure(e?.message));
    }
}

function* createAppointmentFromWellnessRequestSaga(payload: any): any {
    try {
        const response = yield API.post(`/v2/wellness-profile/create-appointment`, payload.payload)
        yield put(
            createAppointmentFromWellnessRequestSuccess(response?.data?.detail)
        );
        if (payload.payload.callback) {
            yield call(payload.payload.callback, response.data?.detail);
        }
        successToaster(response?.data?.meta?.message);
    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            createAppointmentFromWellnessRequestFailure(e?.message)
        );
    }
}


function* updateDentistWellnessProfileHygieneRegimeRequestSaga(payload: any): any {
    try {
        const response = yield API.post(`/v2/wellness-profile/hygiene-regime`, payload.payload)
        yield put(
            updateWellnessHygieneRegimeRequestSuccessAction(response?.data?.detail)
        );
        if (payload.payload.callback) {
            yield call(payload.payload.callback, response.data?.detail);
        }
        successToaster(response?.data?.meta?.message);
    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            updateWellnessHygieneRegimeRequestFailureAction(e?.message)
        );
    }
}

function* getWellnessProfileWatch() {
    yield takeEvery(GET_WELLNESS_REQUEST, getWellnessProfileRequest)
}

function* updateDentistWellnessProfileWatch() {
    yield takeEvery(UPDATE_DENTIST_WELLNESS_PROFILE_REQUEST, updateDentistWellnessProfileRequestSaga);
}

function* updateDentistWellnessComplaintsWatch() {
    yield takeEvery(UPDATE_DENTIST_WELLNESS_COMPLAINTS_REQUEST, updateDentistWellnessComplaintsRequestSaga);
}

function* createAppointmentFromWellnessWatch() {
    yield takeEvery(CREATE_APPOINTMENT_FROM_WELLNESS_REQUEST, createAppointmentFromWellnessRequestSaga);
}

function* updateDentistWellnessProfileHygieneRegimeWatch() {
    yield takeEvery(UPDATE_WELLNESS_HYGIENE_REGIME_REQUEST, updateDentistWellnessProfileHygieneRegimeRequestSaga);
}

export default function* WellnessSaga() {
    yield all([
        getWellnessProfileWatch(),
        updateDentistWellnessProfileWatch(),
        updateDentistWellnessComplaintsWatch(),
        createAppointmentFromWellnessWatch(),
        updateDentistWellnessProfileHygieneRegimeWatch()
    ])
}
