import CrownIcon from './crown.svg';
import FillingIcon from './filling.svg';
import ImplantIcon from './implant.svg';
import MissingToothIcon from './missingTooth.svg';
import RootCanalIcon from './rootCanal.svg';
import WatchIcon from './watch.svg';
import decayIcon from './decay.svg';
import sctIcon from './sct.svg';
import impactedIcon from './Impacted.svg';
import ncclIcon from './nccl.svg';
import periapicalAbscessIcon from './periapicalAbscess.svg';
import groupIcon from './group.svg';

export {
  CrownIcon,
  FillingIcon,
  ImplantIcon,
  MissingToothIcon,
  RootCanalIcon,
  WatchIcon,
  decayIcon,
  sctIcon,
  impactedIcon,
  ncclIcon,
  periapicalAbscessIcon,
  groupIcon
};
