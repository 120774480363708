export const UPDATE_WELLNESS_PROFILE_STEP = 'UPDATE_WELLNESS_PROFILE_STEP';

export const GET_WELLNESS_REQUEST = 'GET_WELLNESS_REQUEST';
export const GET_WELLNESS_REQUEST_SUCCESS = 'GET_WELLNESS_REQUEST_SUCCESS';
export const GET_WELLNESS_REQUEST_FAILURE = 'GET_WELLNESS_REQUEST_FAILURE';

export const UPDATE_DENTIST_WELLNESS_PROFILE_REQUEST = 'UPDATE_DENTIST_WELLNESS_PROFILE_REQUEST';
export const UPDATE_DENTIST_WELLNESS_PROFILE_REQUEST_SUCCESS = 'UPDATE_DENTIST_WELLNESS_PROFILE_REQUEST_SUCCESS';
export const UPDATE_DENTIST_WELLNESS_PROFILE_REQUEST_FAILURE = 'UPDATE_DENTIST_WELLNESS_PROFILE_REQUEST_FAILURE';

//Complaints
export const CHANGE_WELLNESS_COMPLAINTS_REQUEST = 'CHANGE_WELLNESS_COMPLAINTS_REQUEST';
// Patient Preferences
export const CHANGE_WELLNESS_PATIENT_PREFERENCE_REQUEST = 'CHANGE_WELLNESS_PATIENT_PREFERENCE_REQUEST';
// Social Notes
export const CHANGE_WELLNESS_SOCIAL_NOTES_REQUEST = 'CHANGE_WELLNESS_SOCIAL_NOTES_REQUEST';


export const UPDATE_DENTIST_WELLNESS_COMPLAINTS_REQUEST = 'UPDATE_DENTIST_WELLNESS_COMPLAINTS_REQUEST';
export const UPDATE_DENTIST_WELLNESS_COMPLAINTS_REQUEST_SUCCESS = 'UPDATE_DENTIST_WELLNESS_COMPLAINTS_REQUEST_SUCCESS';
export const UPDATE_DENTIST_WELLNESS_COMPLAINTS_REQUEST_FAILURE = 'UPDATE_DENTIST_WELLNESS_COMPLAINTS_REQUEST_FAILURE';

export const CHANGE_WELLNESS_COMPLAINTS_LOCAL_REQUEST = 'CHANGE_WELLNESS_COMPLAINTS_LOCAL_REQUEST';

export const CREATE_APPOINTMENT_FROM_WELLNESS_REQUEST = 'CREATE_APPOINTMENT_FROM_WELLNESS_REQUEST';
export const CREATE_APPOINTMENT_FROM_WELLNESS_REQUEST_SUCCESS = 'CREATE_APPOINTMENT_FROM_WELLNESS_REQUEST_SUCCESS';
export const CREATE_APPOINTMENT_FROM_WELLNESS_REQUEST_FAILURE = 'CREATE_APPOINTMENT_FROM_WELLNESS_REQUEST_FAILURE';


export const CHANGE_WELLNESS_HYGIENE_REGIME_LOCAL_REQUEST = 'CHANGE_WELLNESS_HYGIENE_REGIME_LOCAL_REQUEST';

export const UPDATE_WELLNESS_HYGIENE_REGIME_REQUEST = 'UPDATE_WELLNESS_HYGIENE_REGIME_REQUEST';
export const UPDATE_WELLNESS_HYGIENE_REGIME_REQUEST_SUCCESS = 'UPDATE_WELLNESS_HYGIENE_REGIME_REQUEST_SUCCESS';
export const UPDATE_WELLNESS_HYGIENE_REGIME_REQUEST_FAILURE = 'UPDATE_WELLNESS_HYGIENE_REGIME_REQUEST_FAILURE';
