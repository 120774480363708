import { all, put, takeEvery } from 'redux-saga/effects';
import API from '../../../../../../utils/api';
import { GET_TREATMENT_FEES_REQUEST } from '../../../../../ActionTypes/v2/dentist/clinicalExam/treatment/plannedTreatments';
import {
    getTreatmentDataFeesFailAction,
    getTreatmentDataFeesSuccessAction
} from '../../../../../actions/v2/dentist/plannedTreatments';

function* getTreatmentFeesDataSaga(payload: any): any {
    try {
        const response = yield API.get(`/v2/treatment/tx-value/${payload.payload?.appointmentID}/${payload.payload?.patientID}`);
        const data = response?.data?.detail
        yield put(getTreatmentDataFeesSuccessAction(data));

    } catch (e: any) {
        yield put(getTreatmentDataFeesFailAction(e));
    }
}

function* getTreatmentFeesDataWatcher() {
    yield takeEvery(GET_TREATMENT_FEES_REQUEST, getTreatmentFeesDataSaga);
}

export default function* plannedTreatmentsSaga() {
    yield all([getTreatmentFeesDataWatcher()]);
}
