import {faCaretDown, faCaretUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {isArray} from "lodash";
import moment from "moment";
import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import Select, {components} from "react-select";
import {setExamFindingsAction} from "../../../../../app/actions/v2/dentist/clinicalExam/exam/examFindingsTypesAction";
import {setAlternativeOdontogramAction} from '../../../../../app/actions/v2/dentist/clinicalExam/patientReviewAction';
import {RootState} from "../../../../../app/reducers/v2/rootReducer";
import theme from "../../../../../theme";
import {
    convertValueToRoman,
    removeRefFromJson,
    stringToArray,
    toothNumberPreferences,
    usDateFormat
} from "../../../../../utils/helper";
import {
    completeCheckout,
    odontogram,
    periogram,
    photo,
    viewExam,
    xray
} from '../../../../../utils/v2/clinicalExamConstant';
import {
    PMSEnableFindings,
    FindingList,
    findingTypes,
    PMSEnableFindingsForOdontogram
} from "../../../../../utils/v2/examConstant";
import CheckboxSelect from '../../../common/CheckboxSelect';
import DateSelect from "../../../common/DateSelect";
import DropDown from "../../../common/DropDown";
import InputHeading from "../../../common/InputHeading";
import RadioSelect from "../../../common/RadioSelect";
import RangeSlider from "../../../common/RangeSlider";
import TextBox from "../../../common/TextBox";
import {additionalOdontogramDataAction} from "../../../../../app/actions/v2/dentist/recommendationsTreatmentActions";
import {useLocation} from "react-router-dom";

const useStyles = makeStyles((theme: any) => ({
    multiSelectCssOutlinedInput: {
        width: "100%",
        "&$cssFocused $notchedOutline": {
            border: `2px solid ${theme.palette.v2.primary.main} !important`,
        },
        "& .MuiSelect-select": {
            padding: "10px !important",
            borderRadius: "4px !important",
        },
        "& .select__menu-list": {
            maxHeight: "150px",
        },
        "& .select__control--is-disabled": {
            background: `${theme.palette.common.white} !important`,
            border: `1px solid rgba(0, 0, 0, 0.26) !important`,
            color: `${theme.palette.text.secondary} !important`,
        },
    },
}));
// custom style for react select
const customStyles = {
    control: (provided: Record<string, unknown>) => ({
        ...provided,
        padding: "1px !important",
        boxShadow: "none !important",
        height: "50px",
        borderRadius: "8px !important",
        borderColor: theme.palette.text.darkGrey,
        "&:hover": {
            border: `1px solid ${theme.palette.common.black50} !important`,
            boxShadow: "none !important",
        },
        "&:focus": {
            border: `2px solid ${theme.palette.v2.primary.main} !important`,
            boxShadow: "none !important",
        },
        "&:active": {
            border: `2px solid ${theme.palette.v2.primary.main} !important`,
            boxShadow: "none !important",
        },
    }),
    menu: (provided: any) => ({
        ...provided,
        border: "none",
        boxShadow: "10px 12px 25px 0px rgb(0 0 0 / 25%)",
        background: theme.palette.background.paper,
        borderRadius: "4px",

    }),
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isFocused && "rgba(64, 193, 172, 0.08)",
        color: state.isFocused && `${theme.palette.common.black50}!important`,
        fontFamily: "FavoritPro-Regular !important",
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        flexWrap: "nowrap !important",
        maxWidth: "100%",
        overflow: "scroll",
        fontFamily: "FavoritPro-Regular !important",
        color: theme.palette.common.black50,
        "::-webkit-scrollbar": {
            display: " none",
        },
    }),
    multiValue: (base: any) => ({
        ...base,
        minWidth: "auto !important",
        fontFamily: "FavoritPro-Regular !important",
        color: theme.palette.common.black50,
    }),
    menuPortal: (provided: any) => ({...provided, zIndex: 2025}),
};

type Props = {
    fieldData: any;
    exam: string;
    finding: any;
    index: number;
    isMulti: boolean | undefined;
    tag?: any | undefined;
    findingData?: any;
    disableFindingTypes?: any;
    fromChart?: boolean;
    handleChartChange?: any;
    chartFindingData?: any;
    fromPatientReview?: boolean,
    fromTreatmentPlanning?: boolean
    displayOdontogramForm?: boolean
    fromAnnotation?: boolean
    annotationData?: any
    disabledWellnessAnnotation?: boolean | undefined
};

const ExamsCustomFields = (props: Props) => {
    const {
        fieldData,
        exam,
        finding,
        index,
        isMulti,
        tag,
        findingData,
        disableFindingTypes,
        fromChart,
        handleChartChange,
        chartFindingData,
        fromPatientReview,
        fromTreatmentPlanning,
        displayOdontogramForm,
        fromAnnotation,
        annotationData,
        disabledWellnessAnnotation,
    } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const {palette} = theme as any
    const {
        odontogram: {cameOdontogramFromTreatment},
        appointmentsReducer: {appointmentDetails},
        clinicalExamFilters: {findingType, examFormSize, appointmentStep},
        patientReview: {setAlternativeOdontogram},
        recommendationsTreatment: {additionalOdontogramData},
        wellnessProfileFilters: {wellnessProfileStep, wellnessProfileData},
    } = useSelector((state: RootState) => state) as any
    const location = useLocation()
    const isWellnessProfile = location?.pathname?.includes('/v2/dentist/wellness-profile/')
    const examFindingDataFromSelector = useSelector((state: RootState) => state?.examFindingReducer?.examFindingData);
    const tabData = isWellnessProfile ? wellnessProfileStep : findingType
    const examFindingData = fromChart ? chartFindingData : fromAnnotation ? annotationData : examFindingDataFromSelector as any
    const isPhotoXray = ((tabData === photo || tabData === xray) && appointmentStep !== 4) || fromAnnotation
    const exceptThisSymbols = ["e", "E", "+", "-"];

    const disabledField = useMemo(() => {
        if (fromAnnotation || disabledWellnessAnnotation) {
            return true
        }
        if (cameOdontogramFromTreatment) {
            return !!displayOdontogramForm
        }
        const isEntireTypeDisabled = disableFindingTypes?.includes(findingTypes[tabData]) as any
        if (fromPatientReview || fromTreatmentPlanning) {
            return isEntireTypeDisabled
        } else {
            if ([completeCheckout, viewExam]?.includes(appointmentDetails?.sub_status) ||
                fieldData?.disableInFindingType?.includes(findingTypes[tabData]) || (isWellnessProfile ? wellnessProfileData?.is_wellness_locked : false)) {
                return true
            } else {
                return (
                    isEntireTypeDisabled ||
                    ((tabData === periogram) &&
                        appointmentDetails?.pms_appointment_no !== null &&
                        (!PMSEnableFindings?.includes(finding))
                    ) || ((tabData === odontogram) &&
                        appointmentDetails?.pms_appointment_no !== null &&
                        (!PMSEnableFindingsForOdontogram?.includes(finding))
                    ))
            }
        }
    }, [cameOdontogramFromTreatment, fromPatientReview, fromTreatmentPlanning, disableFindingTypes, tabData, appointmentDetails?.sub_status, fieldData?.disableInFindingType, appointmentDetails?.pms_appointment_no, wellnessProfileData?.is_wellness_locked]);
    const getColumnSize = (options: any, type?: string) => {
        if (type === "radio") {
            let optionLength = 0 as any;
            options?.map((res: any) => (optionLength += res?.label?.length));
            return optionLength > 30 || options?.length > 2 ? 12 : 4;
        } else if (type === "label") {
            return options?.length > 30 ? 12 : 6;
        }
        return 6;
    };
    const DropdownIndicator = (props: any) => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <FontAwesomeIcon
                        color={disabledField ? palette.text.secondary : palette.text.disabled}
                        icon={props?.selectProps?.menuIsOpen ? faCaretUp : faCaretDown}
                    />
                </components.DropdownIndicator>
            )
        );
    };
    const getDropDownOption = (option: any) => {
        return {
            label: option,
            value: option,
        };
    };
    const getIndexOfCurrentTag = () => {
        let index = 0 as number;
        if (fromAnnotation) {
            if (examFindingData && isArray(examFindingData)) {
                index = examFindingData?.findIndex((res: any) => res?.tag_id === tag?.tag_id);
            }
        } else {
            if (examFindingData && isArray(examFindingData)) {
                index = examFindingData?.findIndex((res: any) => res?.key === tag?.key);
            }
        }
        return index || 0;
    };
    const handleChange = (e: any, key: string, exam: string) => {
        const {value} = e?.target as any;
        if (fromPatientReview) {
            dispatch(setAlternativeOdontogramAction({
                ...setAlternativeOdontogram,
                [key]: value
            }))
        } else if (fromTreatmentPlanning) {
            dispatch(additionalOdontogramDataAction({
                ...additionalOdontogramData,
                [key]: value
            }))
        } else {
            let updatedExamFindingData = removeRefFromJson(examFindingData) as any;

            if (updatedExamFindingData && isPhotoXray && fieldData) {
                updatedExamFindingData[getIndexOfCurrentTag()][fieldData?.key || ""] = value;
            } else {
                if (isMulti) {
                    findingData?.map((res: any) => {
                        res?.options?.map((option: any) => {
                            if (option?.dependent !== undefined || option?.dependent) {
                                if (
                                    option?.dependent?.key === key &&
                                    finding &&
                                    updatedExamFindingData[exam] &&
                                    updatedExamFindingData[exam][finding]
                                ) {
                                    let updatedDataForDependent = updatedExamFindingData[exam][finding] as any;
                                    if (updatedDataForDependent[index]) {
                                        updatedDataForDependent[index][option?.key] = null;
                                        if (updatedExamFindingData[exam]) {
                                            updatedExamFindingData[exam][finding] = updatedDataForDependent;
                                        }
                                    }
                                }
                            }
                            if (updatedExamFindingData && exam && finding && updatedExamFindingData[exam] && updatedExamFindingData[exam][finding]) {
                                let updatedData = updatedExamFindingData[exam] && (updatedExamFindingData[exam][finding] as any);
                                if (updatedData && updatedData[index]) {
                                    updatedData[index][key] = value || null;
                                    if (updatedExamFindingData[exam]) {
                                        updatedExamFindingData[exam][finding] = updatedData;
                                    }
                                }
                            }

                            return true;
                        })
                        return true
                    });
                } else {
                    //This function will get called only when the answer/value is changes for question who hase questions depended on it.
                    const removeDependentVal = (dependentOption: any) => {
                        findingData?.map((res: any) => {
                            res?.options?.map((option: any) => {
                                if (option && option?.dependent && option?.key && exam && updatedExamFindingData[exam] && dependentOption && option?.dependent?.key === dependentOption && updatedExamFindingData[exam]) {
                                    updatedExamFindingData[exam][option?.key] = null;
                                }
                                return true;
                            })
                            return true
                        });
                    };
                    findingData?.map((res: any) => {
                        res?.options?.map((dependentOption: any) => {
                            if (
                                dependentOption &&
                                dependentOption?.dependent &&
                                dependentOption?.dependent?.key === key &&
                                !dependentOption?.dependent?.answer?.includes(value)
                            ) {
                                updatedExamFindingData[exam][dependentOption?.key] = null;
                                removeDependentVal(dependentOption?.key);
                            }
                            if (updatedExamFindingData[exam]) {
                                updatedExamFindingData[exam][key] = value || null;
                            }
                            return true;
                        })
                        return true
                    });
                }
            }
            if (fromChart) {
                handleChartChange(updatedExamFindingData)
            } else {
                dispatch(setExamFindingsAction(updatedExamFindingData));
            }
        }
    };
    const handleChangeMultiSelect = (
        e: any,
        d: any,
        key: string,
        exam: string,
        isMultiSelection: boolean
    ) => {
        let val = isMultiSelection ? e?.map((res: any) => res?.value) : e?.value;
        if (fromPatientReview) {
            dispatch(setAlternativeOdontogramAction({
                ...setAlternativeOdontogram,
                [key]: val
            }))
        } else if (fromTreatmentPlanning) {
            dispatch(additionalOdontogramDataAction({
                ...additionalOdontogramData,
                [key]: val
            }))
        } else {
            handleChangeHelper(val, key, exam)
        }
    };
    const handleSliderChange = (val: any, key: string, exam: string) => {
        handleChangeHelper(val, key, exam)
    };
    const handleChangeHelper = (val: any, key: string, exam: string) => {
        let updatedExamFindingData = removeRefFromJson(examFindingData);
        const currentTagIndex = getIndexOfCurrentTag() as number;
        if (
            isPhotoXray &&
            updatedExamFindingData &&
            updatedExamFindingData[currentTagIndex] &&
            fieldData
        ) {
            updatedExamFindingData[currentTagIndex][fieldData?.key || ""] = val;
        } else if (updatedExamFindingData && updatedExamFindingData[exam]) {
            if (isMulti && updatedExamFindingData[exam][finding] && updatedExamFindingData[exam][finding][index]) {
                updatedExamFindingData[exam][finding][index][key] = val;
            } else {
                updatedExamFindingData[exam][key] = val;
            }
        }

        if (fromChart) {
            handleChartChange(updatedExamFindingData)
        } else {
            dispatch(setExamFindingsAction(updatedExamFindingData));
        }
    }
    const fulfilledDependency = () => {
        if (fieldData?.key === "procedure_status" && tabData !== odontogram) {
            return false
        }
        if (isPhotoXray) {
            // as there is no need for dependency question for tag... so commenting below code
            return true;
            // if (fieldData?.dependent && examFindingData && examFindingData[getIndexOfCurrentTag()][fieldData?.dependent.key]) {
            //   if (typeof examFindingData[getIndexOfCurrentTag()][fieldData?.dependent.key] === 'boolean') {
            //     return (examFindingData[getIndexOfCurrentTag()][fieldData?.dependent.key] ? 'yes' : 'no') === fieldData?.dependent.answer
            //   }
            //   return examFindingData[getIndexOfCurrentTag()][fieldData?.dependent.key] === fieldData?.dependent.answer
            // }
        } else if (fieldData && fieldData?.dependent && examFindingData && examFindingData[exam]) {
            if (isMulti) {
                if (
                    fieldData?.dependent.key &&
                    examFindingData[exam][finding] &&
                    examFindingData[exam][finding][index]
                ) {
                    if (
                        typeof examFindingData[exam][finding][index][
                            fieldData?.dependent?.key
                            ] === "boolean"
                    ) {
                        return (
                            (examFindingData[exam][finding][index][fieldData?.dependent?.key]
                                ? "yes"
                                : "no") === fieldData?.dependent?.answer
                        );
                    }
                    return (
                        examFindingData[exam][finding][index][fieldData?.dependent?.key] ===
                        fieldData?.dependent?.answer
                    );
                }
            } else {
                if (fieldData?.dependent?.answer && Array.isArray(fieldData?.dependent?.answer)) {
                    if (typeof examFindingData[exam][fieldData?.dependent?.key] === "boolean") {
                        return fieldData?.dependent?.answer?.includes(examFindingData[exam][fieldData?.dependent?.key] ? "yes" : "no");
                    }
                    return fieldData?.dependent?.answer?.includes(examFindingData[exam][fieldData?.dependent?.key]);
                }
            }
        }
        return fieldData?.dependent === undefined;
    };
    const getValueFromRedux = () => {
        const currentTagIndex = getIndexOfCurrentTag() as number
        if (isPhotoXray && fieldData && examFindingData && examFindingData[currentTagIndex]) {
            return fieldData?.label === "Presence"
                ? "yes"
                : examFindingData[currentTagIndex][fieldData?.key];
        }

        if (fieldData && fieldData?.key && examFindingData && examFindingData[exam]) {
            if (isMulti) {
                if (
                    examFindingData[exam][finding] &&
                    examFindingData[exam][finding][index] &&
                    examFindingData[exam][finding][index][fieldData?.key] !== null
                ) {
                    if (typeof examFindingData[exam][finding][index][fieldData?.key] === "boolean") {
                        return examFindingData[exam][finding][index][fieldData?.key] ? "yes" : "no";
                    }
                    return examFindingData[exam][finding][index][fieldData?.key];
                }
            } else {
                if (examFindingData[exam][fieldData?.key] !== null) {
                    if (typeof examFindingData[exam][fieldData?.key] === "boolean") {
                        return examFindingData[exam][fieldData?.key] ? "yes" : "no";
                    }
                    return examFindingData[exam][fieldData?.key] === 0 ? '0' : examFindingData[exam][fieldData?.key];
                }
            }
        }
        return null;
    };
    const multiSelectValue = (isToothNumber?: boolean, data?: any) => {
        let newData: any
        if (data) {
            newData = data?.map((res: string) => {
                return {
                    label: isToothNumber ? toothNumberPreferences(parseInt(res)) : res,
                    value: res,
                };
            })
        } else {
            const reduxValue = getValueFromRedux() as any;
            if (reduxValue) {
                return (
                    newData = typeof reduxValue === "string"
                        ? stringToArray(reduxValue)
                        : reduxValue
                )?.map((res: string) => {
                    return {
                        label: isToothNumber ? toothNumberPreferences(parseInt(res)) : res,
                        value: res,
                    };
                });
            }
            return reduxValue || null;
        }
        return newData
    };
    const getUsToothNumberValue = (key: any) => {
        if (key) {
            return {
                label: toothNumberPreferences(key),
                value: key,
            };
        }
        return {label: null, value: null};
    };
    const checkBoxChange = (e: any, item: any, key: string, exam: string) => {
        let updatedExamFindingData = removeRefFromJson(examFindingData);
        if (fromPatientReview) {
            let val = setAlternativeOdontogram[key] || [] as any
            if (e?.target?.checked) {
                val?.push(item)
            } else {
                val = setAlternativeOdontogram[key]?.filter((f: any) => f !== item);
            }
            dispatch(setAlternativeOdontogramAction({
                ...setAlternativeOdontogram,
                [key]: val
            }))
        } else if (fromTreatmentPlanning) {
            let val = additionalOdontogramData[key] || [] as any
            if (e?.target?.checked) {
                val?.push(item)
            } else {
                val = additionalOdontogramData[key]?.filter((f: any) => f !== item);
            }
            dispatch(additionalOdontogramDataAction({
                ...additionalOdontogramData,
                [key]: val
            }))
        }
        if (updatedExamFindingData && isPhotoXray && fieldData) {
            let value = updatedExamFindingData[getIndexOfCurrentTag()][fieldData?.key || ""] || []

            if (e?.target?.checked) {
                value?.push(item)
            } else {
                value = updatedExamFindingData[getIndexOfCurrentTag()][fieldData?.key || ""]?.filter((f: any) => f !== item) || [];
            }
            updatedExamFindingData[getIndexOfCurrentTag()][fieldData?.key || ""] = value;
            dispatch(setExamFindingsAction(updatedExamFindingData));
        } else {

            if (
                updatedExamFindingData &&
                updatedExamFindingData[exam] &&
                updatedExamFindingData[exam][finding] &&
                updatedExamFindingData[exam][finding][index]
            ) {

                let val = updatedExamFindingData[exam][finding][index][key] || [] as any
                if (e?.target?.checked) {
                    val?.push(item)
                } else {
                    val = examFindingData[exam][finding][index][key]?.filter((f: any) => f !== item);
                }
                updatedExamFindingData[exam][finding][index][key] = val;
                if (fromChart) {
                    handleChartChange(updatedExamFindingData)
                }

            }
        }

    };
    const getProcedureOptions = () => {
        return fieldData?.options?.filter((opt: any) => {
            if (cameOdontogramFromTreatment || fromTreatmentPlanning) {
                return opt === 'Planned'
            } else {
                return FindingList.find((f: any) => f?.type === 'group' ? f.groupSubType?.includes(finding) : f.key === finding)?.procedures?.includes(opt)
            }
        })
    }
    const customFilterOption = (option: any, rawInput: any) => {
        const label = option.label;
        if (typeof label === 'number') {
            // Convert the number to a string for searching.
            const labelString = label.toString();
            const input = rawInput.toLowerCase(); // Convert the input to lowercase for case-insensitive filtering.

            return labelString.includes(input);
        }

        return false; // Return false for non-number labels.
    };
    const clearClickHandler = (key: string) => {
        let updatedExamFindingData = removeRefFromJson(examFindingData);
        updatedExamFindingData[exam][key] = null;
        dispatch(setExamFindingsAction(updatedExamFindingData));
    }
    const checkboxValue = (key: string, res: any) => {
        return fromPatientReview ?
            setAlternativeOdontogram[key] ?
                setAlternativeOdontogram[key]?.includes(res)
                : null
            : fromTreatmentPlanning
                ? additionalOdontogramData[key]
                    ? additionalOdontogramData[key]?.includes(res)
                    : null
                : getValueFromRedux()?.includes(res)
    }
    return (
        <React.Fragment>
            {fulfilledDependency() ? (
                <>{(fieldData?.type === "select" && !fieldData?.key?.includes('tooth_number')) && (fieldData?.key === 'extent' ? appointmentDetails?.appointmentType !== 'NPE' : true) && (
                    <Grid
                        item
                        xs={12}
                        sm={examFormSize ? 6 : 12}
                        md={examFormSize ? 4 : 12}
                        lg={examFormSize ? 3 : 12}
                        xl={examFormSize ? 3 : 6}
                    >
                        <InputHeading
                            title={fieldData?.label}
                        />
                        <DropDown
                            selectOptions={fieldData?.options}
                            selectProps={{
                                id: fieldData?.key,
                                name: fieldData?.key,
                                value: (fromPatientReview ? setAlternativeOdontogram[fieldData?.key] ? setAlternativeOdontogram[fieldData?.key] : null : fromTreatmentPlanning
                                    ? additionalOdontogramData[fieldData?.key]
                                        ? additionalOdontogramData[fieldData?.key]
                                        : null : ((finding === 'mobility' && fieldData?.key === 'grade') ? convertValueToRoman(getValueFromRedux()) : getValueFromRedux())) || null,
                                onChange: (e: any) => handleChange(e, fieldData?.key, exam),
                                disabled: disabledField,
                            }}
                            sx={{height: '50px'}}
                        />
                    </Grid>
                )}
                    {(fieldData?.type === "multi-select" || (fieldData?.type === "select" && fieldData?.key?.includes('tooth_number'))) && (
                        <Grid
                            item
                            xs={6}
                            sm={examFormSize ? 6 : 12}
                            md={examFormSize ? 4 : 12}
                            lg={examFormSize ? 3 : 12}
                            xl={examFormSize ? 3 : 6}
                            className="reactSelect"
                        >
                            <InputHeading
                                title={fieldData?.label}
                            />

                            <Select
                                menuPortalTarget={document.body}
                                menuShouldScrollIntoView={false}
                                menuPlacement='auto'
                                placeholder=""
                                components={{
                                    IndicatorSeparator: () => null,
                                    DropdownIndicator,
                                }}
                                value={fromPatientReview ?
                                    (setAlternativeOdontogram[fieldData?.key] ?
                                        getUsToothNumberValue(setAlternativeOdontogram[fieldData?.key])
                                        : null) : fromTreatmentPlanning ?
                                        (additionalOdontogramData[fieldData?.key] ?
                                            getUsToothNumberValue(additionalOdontogramData[fieldData?.key])
                                            : null) :
                                        (fieldData?.type === "multi-select" ? multiSelectValue(fieldData?.key?.includes('tooth_number'))
                                            : getUsToothNumberValue(getValueFromRedux())) || null}
                                styles={customStyles}
                                isMulti={fieldData?.type === "multi-select"}
                                isDisabled={disabledField}
                                id={fieldData?.key}
                                name={fieldData?.key}
                                filterOption={(fieldData?.key?.includes('tooth_number') ? customFilterOption : undefined)} //TODO changes if tooth_number include in other field
                                options={(fieldData?.key?.includes('tooth_number')) ?
                                    fieldData?.options
                                        ?.map((res: string) => ({
                                            label: toothNumberPreferences(parseInt(res)),
                                            value: res,
                                        }))?.sort((a: any, b: any) => parseInt(a?.label) - parseInt(b?.label))
                                    : fieldData?.options?.map((res: any) => getDropDownOption(res))
                                }
                                className={`${classes.multiSelectCssOutlinedInput} border-radius-10`}
                                classNamePrefix="select"
                                onChange={(e: any, d: any) => {
                                    handleChangeMultiSelect(e, d, fieldData?.key, exam, fieldData?.type === "multi-select");
                                }}
                            />
                        </Grid>
                    )}

                    {fieldData?.type === "radio" && ((!(fieldData?.key === "procedure_status" && fromTreatmentPlanning))) && (
                        <Grid
                            item
                            xs={12}
                            sm={((fromChart || fromPatientReview || fromTreatmentPlanning) || fieldData?.label?.length < 1) ? 12 : (examFormSize ? getColumnSize(fieldData?.options, "radio") : 12)}
                            md={((fromChart || fromPatientReview || fromTreatmentPlanning) || fieldData?.label?.length < 1) ? 12 : (examFormSize ? getColumnSize(fieldData?.options, "radio") : 12)}
                            lg={((fromChart || fromPatientReview || fromTreatmentPlanning) || fieldData?.label?.length > 15) ? 12 : (fieldData?.key === 'procedure_status' ? 12 : examFormSize ? getColumnSize(fieldData?.options, "radio") : 12)}
                            xl={((fromChart || fromPatientReview || fromTreatmentPlanning) || (fieldData?.label?.length || fieldData?.key?.length) > 15) ? 12 : (fieldData?.key === 'procedure_status' ? 12 : examFormSize ? getColumnSize(fieldData?.options, "radio") : 6)}
                        >
                            {fieldData?.label?.length > 1 && (fromChart ?
                                    <Typography color={palette.v2.primary.main}
                                                className='f-w-500 fw-medium f-14 lh-16'>
                                        {fieldData?.label}
                                    </Typography> :
                                    <InputHeading
                                        sx={{marginBottom: fromPatientReview || fromTreatmentPlanning ? '0px' : ''}}
                                        title={fieldData?.label}
                                    />

                            )}
                            <RadioSelect
                                disabled={disabledField || (isPhotoXray && fieldData?.label === "Presence")}
                                options={(fromChart || fromPatientReview || fromTreatmentPlanning) ?
                                    (fieldData?.key === "procedure_status" ? getProcedureOptions() : fieldData?.options)?.map((res: any) =>
                                        getDropDownOption(res)
                                    ) : (fieldData?.key === "cracks") ? fieldData?.options?.map((res: any) =>
                                        getDropDownOption(res)
                                    ) : fieldData?.options}
                                groupProps={{
                                    name: fieldData?.key,
                                    id: fieldData?.key,
                                    value: (fromPatientReview ? setAlternativeOdontogram[fieldData?.key] : fromTreatmentPlanning ? additionalOdontogramData[fieldData?.key] : getValueFromRedux()) || null,
                                    onChange: (e: any) => handleChange(e, fieldData?.key, exam),
                                }}
                            />
                        </Grid>
                    )}
                    {fieldData?.type === "number" && (
                        <Grid
                            item
                            xs={12}
                            sm={examFormSize ? ((fieldData?.label?.length > 30) ? 12 : 6) : 12}
                            md={fromChart ? 12 : examFormSize ? ((fieldData?.label?.length > 30) ? 12 : 4) : 12}
                            lg={fromChart ? 12 : examFormSize ? ((fieldData?.label?.length > 30) ? 6 : 3) : 12}
                            xl={fromChart ? 12 : examFormSize ? ((fieldData?.label?.length > 30) ? 4 : 3) : (fieldData?.label?.length > 30) ? 12 : 6}
                        >
                            <TextBox
                                examFromChart={fromChart}
                                labelSx={{whiteSpace: 'break-spaces !important'}}
                                disabled={disabledField}
                                label={fieldData?.label}
                                labelProps={{htmlFor: fieldData?.key}}
                                inputProps={{
                                    id: fieldData?.key,
                                    name: fieldData?.key,
                                    onWheel: (e: any) => {
                                        e.target.blur()
                                    },
                                    onKeyDown: (e: any) =>
                                        exceptThisSymbols.includes(e.key) && e.preventDefault(),
                                    onChange: (e: any) => {
                                        if (fieldData?.key === "ortho_tx_age") {
                                            const onlyNum = e.target.value.replace(/[^0-9]/g, "");
                                            if (onlyNum.length < 4) {
                                                if (e.target.value >= 0) {
                                                    handleChange(e, fieldData?.key, exam)
                                                }
                                            }
                                        } else {
                                            if (e.target.value >= 0) {
                                                handleChange(e, fieldData?.key, exam)
                                            }
                                        }
                                    },
                                    value: getValueFromRedux() || null,
                                    fullWidth: true,
                                    type: fieldData?.type,
                                }}
                            />
                        </Grid>
                    )}
                    {fieldData?.type === "text" && fieldData?.multiline === undefined && !fromAnnotation && !fromChart && !fromPatientReview && !fromTreatmentPlanning && (
                        <Grid
                            item
                            xs={getColumnSize(fieldData?.label, "label")}
                            sm={examFormSize ? ((fieldData?.label?.length > 30) ? 12 : 6) : 12}
                            md={examFormSize ? ((fieldData?.label?.length > 30) ? 12 : 4) : 12}
                            lg={examFormSize ? ((fieldData?.label?.length > 30) ? 6 : 3) : 12}
                            xl={examFormSize ? ((fieldData?.label?.length > 30) ? 4 : 3) : (fieldData?.label?.length > 30) ? 12 : 6}
                        >
                            <TextBox
                                labelSx={{whiteSpace: 'break-spaces !important'}}
                                disabled={disabledField}
                                label={fieldData?.label}
                                labelProps={{htmlFor: fieldData?.key}}
                                maxLength={30}
                                inputProps={{
                                    id: fieldData?.key,
                                    name: fieldData?.key,
                                    onChange: (e: any) => handleChange(e, fieldData?.key, exam),
                                    value: getValueFromRedux() || null,
                                    fullWidth: true,
                                }}
                            />
                        </Grid>
                    )}
                    {fieldData?.type === "text" && fieldData?.multiline && (
                        <Grid item xs={12}>
                            <TextBox
                                disabled={disabledField}
                                label={fieldData?.label}
                                labelProps={{htmlFor: fieldData?.key}}
                                maxLength={fieldData?.key === 'medical_comments' ? '' : 50}
                                inputProps={{
                                    id: fieldData?.key,
                                    name: fieldData?.key,
                                    onChange: (e: any) => handleChange(e, fieldData?.key, exam),
                                    value: getValueFromRedux() || null,
                                    fullWidth: true,
                                    multiline: true,
                                    rows: 3,
                                }}
                            />
                        </Grid>
                    )}
                    {fieldData?.type === "slider" && (
                        <Grid item xs={12} mb={1}>
                            <RangeSlider
                                sliderTitle={fieldData?.label}
                                leftLabel={""}
                                rightLabel={""}
                                sliderProps={{
                                    step: 1,
                                    min: fieldData?.min,
                                    max: fieldData?.max,
                                    marks: fieldData?.options,
                                    size: "medium",
                                    value: getValueFromRedux() || null,
                                    disabled: disabledField,
                                }}
                                onChange={(e: any) => handleSliderChange(e, fieldData?.key, exam)}
                            />
                        </Grid>
                    )}
                    {fieldData?.type === "date" && (
                        <Grid
                            item
                            xs={12}
                            sm={examFormSize ? 6 : 12}
                            md={examFormSize ? 4 : 12}
                            lg={examFormSize ? 3 : 12}
                            xl={examFormSize ? 3 : 12}
                            mb={1}
                        >
                            {fieldData?.label?.length > 1 && (<InputHeading title={fieldData?.label}/>)}
                            <DateSelect
                                disabled={disabledField}
                                value={getValueFromRedux() || null}
                                showClearIcon
                                clearClickHandler={() =>
                                    clearClickHandler(fieldData?.key)
                                }
                                onChange={(newValue: any) => {
                                    let newFormatted = moment(new Date(newValue))?.format(usDateFormat);
                                    handleSliderChange(newFormatted, fieldData?.key, exam);
                                }}
                            />
                        </Grid>
                    )}
                    {fieldData?.type === "checkbox" && fieldData?.key !== 'tooth_number' && !disableFindingTypes?.includes(findingTypes[tabData]) && (
                        <Grid item xs={12} mb={(fromPatientReview || fromTreatmentPlanning) ? 0 : 1}>
                            {fieldData?.label.length > 1 && fromChart ? (
                                    <Typography color={palette.v2.primary.main} className='f-w-500 fw-medium f-14 lh-16'>
                                        {fieldData?.label}
                                    </Typography>) :
                                <InputHeading
                                    sx={{marginBottom: (fromPatientReview || fromTreatmentPlanning) ? '0px' : ''}}
                                    title={fieldData?.label}
                                />

                            }
                            {fieldData?.options?.map((res: any, j: number) =>
                                <CheckboxSelect
                                    sx={{marginBottom: (fromPatientReview || fromTreatmentPlanning) ? '0px !important' : ''}}
                                    key={j}
                                    label={res}
                                    checkBoxProps={{
                                        name: fieldData?.key,
                                        checked: checkboxValue(fieldData?.key, res),
                                        value: checkboxValue(fieldData?.key, res),
                                        onChange: (e: any) => checkBoxChange(e, res, fieldData?.key, exam),
                                        disabled: disabledField
                                    }}
                                />
                            )}
                        </Grid>)
                    }
                </>
            ) : null
            }
        </React.Fragment>
    );
};

export default ExamsCustomFields